const cSymbol = (locale: string, currency: string | undefined) =>
  currency
    ? (0).toLocaleString(
      locale,
      {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
    ).replace(/\d/g, '').trim()
    : '';

export default cSymbol;
