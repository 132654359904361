import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { MYSQL, URL } from 'bundles/constants';
import { WithFormControlProps } from 'bundles/hocs/withFormControl/types';
import axios from 'bundles/apis/defaults';

import './styles.scss';

const TinyMce = React.forwardRef((props: WithFormControlProps, ref: (arg0: HTMLInputElement) => HTMLInputElement) => {
  const editorRef = useRef<any>(null);
  let content = props.value;

  const handleEditorChange = (value: string, editor: any) => {
    if (value.length - 1 > parseInt(MYSQL.TEXT_LENGTH)) {
      editor.setContent(value.substring(0, parseInt(MYSQL.TEXT_LENGTH)));
    } else {
      content = value;
      props.onChange(value);
    }
  };

  const imagesUploadHandler = (blobInfo: any) => new Promise<string>((resolve) => {
    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());

    axios.post(URL.TINYMCE_ASSETS_CREATE, formData).then(response => { resolve(response.data.location); });
  });


  if (props.rest.control.getFieldState(props.rest.name, props.rest.control._formState).invalid) {
    editorRef.current.editorContainer.classList.add('form-error');
  } else {
    editorRef.current?.editorContainer.classList.remove('form-error');
  }

  return (
    <>
      {/* @ts-ignore*/}
      <Editor
        id={props.rest.id}
        apiKey={process.env.tiny_mce_api_key}
        onInit={(evt, editor) => editorRef.current = editor}
        textareaName={props.rest.name}
        init={{
          setup: (editor) => {
            editor.on('focus', (e) => e.target.editorContainer.classList.add('focus'));
            editor.on('blur', (e) => e.target.editorContainer.classList.remove('focus'));
          },
          image_title: true,
          file_picker_types: 'image',
          automatic_uploads: true,
          images_upload_handler: imagesUploadHandler,
          plugins: 'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table code help wordcount',
          toolbar: 'undo redo | formatselect | image | link bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
        }}
        {...props.rest}
        value={content}
        onEditorChange={handleEditorChange}
        ref={instance => (instance && ref(editorRef.current as HTMLInputElement))}
      />
    </>
  );
});

TinyMce.displayName = 'TinyMce';

export default TinyMce;
