import React from 'react';
import PropTypes from 'prop-types';

import If from '@Components/common/If';

import './PlaceHolder.scss';

const Shape = ({ condition, type, className, size }) =>
  <If condition={condition === undefined || condition}>
    <div className={`element-placeholder--row ${className}`}>
      <div className={`element-placeholder element-placeholder--${type}`} style={{ height: size }} />
    </div>
  </If>;

Shape.propTypes = {
  condition: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string
};

Shape.defaultProps = {
  className: ''
};

export default Shape;
