import React from 'react';
import PropTypes from 'prop-types';

import If from '@Components/common/If';

import './PlaceHolder.scss';

const Button = ({ condition, className, size }) =>
  <If condition={condition === undefined || condition}>
    <div className={`element-placeholder--row ${className}`}>
      <div className={'element-placeholder element-placeholder--button'} data-size={size} />
    </div>
  </If>;

Button.propTypes = {
  condition: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string
};

Button.defaultProps = {
  className: ''
};

export default Button;
