import React, { FC } from 'react';
import { Table as BTable } from 'react-bootstrap';

import If from 'bundles/components/common/If';
import { TableProps } from './types';

import './PlaceHolder.scss';

const Table: FC<TableProps> = ({ condition, th_count = 4, td_count = 4, hasActionColumn }: TableProps) =>
  <If condition={condition === undefined || condition}>
    <BTable>
      <thead>
        <tr>
          {[...Array(th_count)].map((e, i) =>
            <th key={i} className="element-placeholder--th">
              {((i + 1) === th_count) && hasActionColumn ? null : <p className="element-placeholder element-placeholder--text mt-2 mb-2" /> }
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {[...Array(td_count)].map((e, i) =>
          <tr key={i} className="element-placeholder--row">
            <td className="w-15 element-placeholder--td"><p className="element-placeholder element-placeholder--text mb-0" /></td>
            <td className="w-25 element-placeholder--td"><p className="element-placeholder element-placeholder--text mb-0" /></td>
            <td className="w-15 element-placeholder--td"><p className="element-placeholder element-placeholder--text mb-0" /></td>
            <td className="w-25 element-placeholder--td"><p className="element-placeholder element-placeholder--text mb-0" /></td>
          </tr>
        )}
      </tbody>
    </BTable>
  </If>;

export default Table;
