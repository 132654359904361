import subMonths from 'date-fns/subMonths';
import addMonths from 'date-fns/addMonths';

export const setDateName = (name: string) => {
  if(/start/g.test(name)) return { startDate: name, endDate: name.replace('start', 'end') };

  return { startDate: name.replace('end', 'start'), endDate: name };
};

export const setSelectedDate = (props: Record<string, any>, value: Date) => value ? new Date(value) : null;

export const setStartDate = (props: Record<string, any>, watchStartDate: Date) => watchStartDate ? new Date(watchStartDate) : null;

export const setEndDate = (props: Record<string, any>, watchEndDate: Date) => watchEndDate ? new Date(watchEndDate) : null;

export const setMinDate = (props: Record<string, any>, watchStartDate: Date) => {
  if(props.minDate) return new Date(props.minDate);
  if(props.selectsEnd) return new Date(watchStartDate);

  return subMonths(new Date(), 84);
};

export const setMaxDate = (props: Record<string, any>) => props.maxDate ? props.maxDate : addMonths(new Date(), 1200);

export const isRange = (props: Record<string, any>) => Boolean(props.selectsStart || props.selectsEnd);

