import React from 'react';

import { processFieldName } from 'bundles/utils/index';
import { FormFieldInterface } from '../types';

import './styles.scss';

const Radio = ({ register, errors = {}, validate = {}, name = '', classNameGroup = '', label, ...rest }: FormFieldInterface) =>
  <div className={`custom-control custom-radio ${classNameGroup}`.trim()}>
    <input
      id={rest.id || name}
      type="radio"
      {...register!(name, { validate: validate })}
      className={`custom-control-input ${processFieldName(errors, name) ? 'form-error' : ''}`}
      {...rest}
    />
    <label htmlFor={rest.id} className={`custom-control-label ${validate.isRequired ? 'required' : ''}`.trim()}>
      {label}
    </label>
  </div>;

Radio.displayName = 'Radio';

export default Radio;



