import React, { FC, ChangeEvent } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Form } from 'react-bootstrap';

import If from 'bundles/components/common/If';
import { processFieldName } from 'bundles/utils';
import { PropsWithFormGroupComponent, PropsWithFormGroupWrapper } from './types';

const withFormGroup = (Component: FC<PropsWithFormGroupComponent>): FC<PropsWithFormGroupWrapper> => {
  const Wrapper: FC<PropsWithFormGroupWrapper> = ({
    validate = {},
    label,
    errors = {},
    id,
    type = 'text',
    render = () => undefined,
    options = [],
    refField,
    register = (arg0: any, arg1: any) => { return { arg0, arg1 } as any; },
    onChange,
    classNameGroup,
    withSelectOption,
    disabledWithSkipValidation,
    ...rest
  }) => {
    const { ref, ...restRegister } = register(rest.name, { validate: validate, onChange: onChange, disabled: Component.displayName === 'MultiSelect' ? disabledWithSkipValidation || undefined : disabledWithSkipValidation });

    rest.className = `form-control field-${Component.displayName?.toLowerCase()} ${rest.className ? rest.className : ''} ${processFieldName(errors, rest.name) ? 'form-error' : ''}`.trim();

    rest.ref = (e: ChangeEvent<HTMLInputElement>) => { ref(e); if (refField) refField.current = e; };

    rest.type = Component.displayName === 'Input' ? type : null;

    rest.validate = Component.displayName === 'PhoneNumber' ? validate : null;

    const multiSelectLabel = Component.displayName === 'MultiSelect' ? '_input' : '';

    return (
      <Form.Group className={classNameGroup}>
        <>
          <If condition={label}>
            <Form.Label
              htmlFor={id || `${rest.name}${multiSelectLabel}`.trim()}
              className={`label-${Component.displayName?.toLowerCase()} ${(validate?.isRequired || validate?.isSelectRequired || validate?.isMultiSelectRequired) ? 'required':''}`}
              dangerouslySetInnerHTML={{ __html: label || '' }}
            />
          </If>

          <Component rest={{ ...rest, id: id || rest.name }} register={restRegister} options={options} withSelectOption={withSelectOption} disabledWithSkipValidation={disabledWithSkipValidation} />
          <ErrorMessage errors={errors} name={rest.name} className="form-error" as="p" />
          {render()}
        </>
      </Form.Group>
    );
  };

  return Wrapper;
};

export default withFormGroup;
