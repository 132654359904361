import DatePicker from './DatePicker';
import Input from './Input';
import InputGroup from './InputGroup';
import MultiSelect from './MultiSelect';
import PhoneNumber from './PhoneNumber/index';
import Select from './Select';
import Textarea from './Textarea';
import FileUpload from './FileUpload';
import TinyMce from './TinyMce';

import withFormControl from 'bundles/hocs/withFormControl';
import withFormGroup from './withFormGroup';

export const DatePickerFormGroup = withFormGroup(withFormControl(DatePicker));
export const FileUploadFormGroup = withFormGroup(withFormControl(FileUpload));
export const InputFormGroup = withFormGroup(Input);
export const InputGroupFormGroup = withFormGroup(InputGroup);
export const MultiSelectFormGroup = withFormGroup(withFormControl(MultiSelect));
export const PhoneFormGroup = withFormGroup(withFormControl(PhoneNumber));
export const SelectFormGroup = withFormGroup(Select);
export const TextareaFormGroup = withFormGroup(Textarea);
export const TinyMceFormGroup = withFormGroup(withFormControl(TinyMce));

export { default as Checkbox } from './Checkbox';
export { default as Radio } from './Radio';
