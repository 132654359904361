import subYears from 'date-fns/subYears';
import getYear from 'date-fns/getYear';
import getDate from 'date-fns/getDate';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';

import { i18n } from 'bundles/utils';
import { Months } from 'bundles/types';

export const yearsOptionsBaseOnAgeOfMajority = (aom: string) => {
  const years = [[i18n.t('labels.year'), '']];
  let minAoMYear = getYear(subYears(new Date(), Number(aom)));
  const hundredYearsRange = getYear(subYears(subYears(new Date(), Number(aom)), 100));

  for (; minAoMYear >= hundredYearsRange; minAoMYear--) { years.push([ String(minAoMYear), String(minAoMYear) ]); }

  return years;
};

export const monthsOptions = () => {
  const mo = [[i18n.t('labels.month'), '']];

  Object.values(Months).map((m, i) => mo.push([ m, String(i + 1) ]));

  return mo;
};

export const daysOptions = (year: number = new Date().getFullYear(), month = 0) => {
  const days = [[i18n.t('labels.day'), '']];
  const _lastDayOfMonth = getDate(lastDayOfMonth(new Date(year, month)));

  for (let day = 0; day < _lastDayOfMonth; day++) { days.push([String(day + 1), String(day + 1)]); }

  return days;
};
