import React from 'react';

import { FormFieldInterface } from './types';

import './Input.scss';

const Input = ({ register, rest }: FormFieldInterface) => <input {...rest} {...register} />;

Input.displayName = 'Input';

export default Input;
