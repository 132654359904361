import React, { useContext, FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Button } from 'react-bootstrap';

import If from 'bundles/components/common/If';
import { FilterContext } from 'bundles/contexts/FilterContext/FilterContextProvider';
import Loader from 'bundles/components/common/Loader';

const AdditionalCriteria = React.forwardRef(({ AddCriteriaContent }: { AddCriteriaContent: FC }, ref: RefObject<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(FilterContext);

  const handleCloseFilter = () => dispatch({ type: 'TOGGLE_SHOW_ADD_CRITERIA', payload: { showAddCriteria: false } });

  return (
    <If condition={state.showAddCriteria}>
      <Card className="card--filter over filter__additional-criteria" ref={ref}>
        <Card.Body>
          <AddCriteriaContent />
          <div className="text-right">
            <Button variant="default mr-2" onClick={handleCloseFilter}>{t('labels.cancel')}</Button>
            <Button type="submit" className="position-relative">
              <Loader url={state.url} className="text-center loading--over loading--with-fog" />
              {t('labels.submit')}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </If>
  );
});

AdditionalCriteria.displayName = 'AdditionalCriteria';

export default AdditionalCriteria;
