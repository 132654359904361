import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';

import { locale, opt } from './utils';
import { DatePickerInstance } from './types';
import { WithFormControlProps, InputRef } from 'bundles/hocs/withFormControl/types';

registerLocale('locale', locale);

const ReactDatePicker = React.forwardRef((props: WithFormControlProps, ref: InputRef) => {
  const { t } = useTranslation();
  const { value = props.value ? new Date(props.value) : null, ...rest } = props.rest;
  const watchSD = opt.isRange(props.rest) ? useWatch({ control: props.rest.control, name: opt.setDateName(props.rest.name).startDate }) : null;
  const watchED = opt.isRange(props.rest) ? useWatch({ control: props.rest.control, name: opt.setDateName(props.rest.name).endDate }) : null;

  return (
    <DatePicker
      name={rest.name}
      className={rest.className}
      placeholderText={rest.placeholder || t('labels.date_format')}
      locale="locale"
      dateFormat={rest.dateFormat || 'yyyy-MM-dd'}
      autoComplete="off"
      startDate={opt.setStartDate(props.rest, watchSD)}
      endDate={opt.setEndDate(props.rest, watchED)}
      monthsShown={rest.monthsShown || 2}
      maxDate={opt.setMaxDate(props.rest)}
      minDate={opt.setMinDate(props.rest, watchSD)}
      onChange={(v: string) => props.onChange(v)}
      selected={opt.setSelectedDate(props.rest, value)}
      {...rest}
      ref={(instance: DatePickerInstance) => (instance && ref(instance.input))}
    />
  );
});

ReactDatePicker.displayName = 'Datepicker';

export default ReactDatePicker;
