import React from 'react';
import PropTypes from 'prop-types';

import If from '@Components/common/If';

import './PlaceHolder.scss';

const Group = ({ condition, children, className }) =>
  <If condition={condition === undefined || condition}>
    <div className={`position-relative ${className}`}>
      {children}
    </div>
  </If>;

Group.propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.array,
  className: PropTypes.string
};

Group.defaultProps = {
  className: ''
};

export default Group;
