import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';

import SignInForm from 'bundles/components/public/signIn/SignInForm';
import { LoginViewProps } from 'bundles/types';
import If from 'bundles/components/common/If';
import { URL } from 'bundles/constants';

const SignInView = (props: LoginViewProps) => {
  const { i18n } = useTranslation();

  const title = window.location.pathname === `/${i18n.language}${URL.ADMINS}` || window.location.pathname === URL.ADMINS
    ? i18n.t('titles.administrator_login')
    : i18n.t('labels.signin');

  return (
    <div className="sign-in">
      <If condition={props.inline_message}>
        <div className="d-flex justify-content-center" dangerouslySetInnerHTML={{ __html: props.inline_message || '' }} />
      </If>

      <Card>
        <Card.Header as={'h1'} className="border-0">{title}</Card.Header>
        <Card.Body>
          <SignInForm {...props} />
        </Card.Body>
      </Card>
    </div>
  );
};

const SignInViewWrapper: FC<LoginViewProps> = (props) => <SignInView {...props} />;

export default SignInViewWrapper;
