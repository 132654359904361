import React from 'react';

import { processFieldName } from 'bundles/utils';
import { FormFieldInterface } from './types';

import './Input.scss';

const Checkbox = ({ register, errors = {}, validate = {}, name = '', classNameGroup = '', label, ...rest }: FormFieldInterface) =>
  <div className={`custom-control custom-checkbox ${classNameGroup}`.trim()}>
    <input
      id={rest.id || name}
      type="checkbox"
      {...register!(name, { validate: validate })}
      className={`custom-control-input ${processFieldName(errors, name) ? 'form-error' : ''}`}
      {...rest}
    />
    <label htmlFor={name} className={`custom-control-label ${validate.isRequired ? 'required' : ''}`.trim()}>
      {label}
    </label>
  </div>;

Checkbox.displayName = 'Checkbox';

export default Checkbox;



