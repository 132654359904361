export const setWalletsOptionsFor = (wallets = [], target) => {
  const arr = [];

  wallets.map(e => arr.push({
    select_options_name: e.currency.code,
    select_options_value: target === 'batch payouts' ? e.currency.id : e.id,
    select_options_data_attrs: { 'data-alt-value': e.currency.exponent },
  })
  );

  return arr;
};
