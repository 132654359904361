import React, { useCallback } from 'react';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';

import { RecaptchaProps } from './types';

const Recaptcha = ({ sitekey, register, setValue, refreshReCaptcha, action }: RecaptchaProps) => {
  const { i18n } = useTranslation();
  const handleVerify = useCallback((token: string) => setValue('g-recaptcha-response', token), []);

  return (
    <GoogleReCaptchaProvider language={i18n.language} useRecaptchaNet={true} useEnterprise={true} reCaptchaKey={sitekey}>
      <GoogleReCaptcha onVerify={handleVerify} refreshReCaptcha={refreshReCaptcha} action={action} />

      <input type="hidden" {...register('g-recaptcha-response')} />
    </GoogleReCaptchaProvider>
  );
};

export default Recaptcha;
