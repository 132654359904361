import React, { FC, useRef, useEffect, MouseEvent } from 'react';
import { Form } from 'react-bootstrap';

import { URL } from 'bundles/constants';
import { IWithSessionProps } from './types';
import { triggerSession } from './utils';

const withSession = (Component: FC<Record<string, any>>) => {
  const ComponentWrapper = (props: IWithSessionProps) => {
    const refForm = useRef<HTMLFormElement | null>(null);

    const handleSignOut = (e?: MouseEvent<HTMLLIElement>) => {
      if(props.user_signed_in) {
        e?.preventDefault?.();
        triggerSession('end', refForm);
      }
    };

    useEffect(() => { (props.user_signed_in && props.shouldTriggerSessionStart) && triggerSession('start', refForm); }, []);

    return (
      <>
        <Form
          action={`/en${URL.SESSION}`}
          acceptCharset="UTF-8"
          method="post"
          ref={refForm}
          data-testid="sign-out-form"
          data-zendesk-logout-url={props.current_user?.zendesk_logout_url}
          data-heart-beat-interval={props.current_user?.heart_beat_interval}
          className="d-inline"
        >
          <input type="hidden" name="_method" value="delete" />
          <input type="hidden" name="authenticity_token" value={(window.csrfToken === undefined ? '' : window.csrfToken)} />
          <input type="hidden" name="user_type" id="user_type" value={(props.user_type_param === undefined ? '' : props.user_type_param)} />
        </Form>

        <Component {...props} handleSignOut={handleSignOut} />
      </>
    );
  };

  ComponentWrapper.displayName = `withSession(${Component.displayName || Component.name || 'Component'})`;

  return ComponentWrapper;
};

export default withSession;
