import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

const withFormControl = (Component: FC<Record<string, any>>) => {
  const Wrapper = (props: any) =>
    <Controller
      control={props.rest.control}
      name={props.rest.name}
      render={({ field: { onChange, value, ref } }) => (
        <Component
          onChange={(v: any[]) => {
            onChange(v);
            props.rest.handleOnChange && props.rest.handleOnChange(v);
          }}
          value={value}
          ref={ref}
          {...props}
        />
      )}
    />;

  Wrapper.displayName = Component.displayName || 'withFormControlWrapper';

  return Wrapper;
};

export default withFormControl;
