import React, { useState, useEffect, useRef } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';

import { WithFormControlProps, InputRef } from 'bundles/hocs/withFormControl/types';

import './styles.scss';

const MultiSelect = React.forwardRef((props: WithFormControlProps, ref: InputRef) => {
  const { t } = useTranslation();
  const [focused, setFocused] = useState('');
  let inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const instance = inputRef.current;

    return () => {
      (instance as HTMLInputElement).removeEventListener('focus', () => setFocused('focus'));
      (instance as HTMLInputElement).removeEventListener('blur', () => setFocused(''));
    };
  }, []);

  return (
    <Multiselect
      options={props.options}
      selectedValues={props.rest.selectedValues || props.value}
      displayValue={props.rest.displayValue || 'name'}
      closeIcon="circle"
      closeOnSelect={false}
      disable={props.disabledWithSkipValidation || props.rest.disabled}
      showArrow={true}
      customArrow={<FontAwesomeIcon icon={faAngleDown} />}
      onSelect={props.onChange}
      onRemove={(v) => props.onChange(v)}
      className={`form-control ${focused} ${props.rest.control.getFieldState(props.rest.name, props.rest.control._formState).invalid ? 'form-error' : ''}`.trim()}
      id={props.rest.id}
      placeholder={props.rest.placeholder || t('labels.select')}
      disablePreSelectedValues={props.rest.disablePreSelectedValues}
      ref={instance => {
        if (instance) {
          inputRef = (instance as Record<string, any>).searchBox;
          inputRef.current?.addEventListener('focus', () => setFocused('focus'));
          inputRef.current?.addEventListener('blur', () => setFocused(''));

          ref(inputRef.current as HTMLInputElement);
        }
      }} />
  );
});

MultiSelect.displayName = 'MultiSelect';

export default MultiSelect;

