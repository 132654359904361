import { i18n } from './index';

const buildSelectOptions = (arr, value, title, defaultOption = i18n.t('labels.all')) => {
  const optionsArr = [[defaultOption, '']];

  arr.forEach(item => optionsArr.push([item[title], item[value]]));

  return optionsArr;
};

export default buildSelectOptions;
