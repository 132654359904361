const processFieldName = (names, name) => {
  if(!names) return;
  const nameProcessed = name.replace(/[[,\]]/g, ' ').trim();

  return getValue(names, nameProcessed);
};

const getValue = (object, nameProcessed) => nameProcessed.split(' ').filter(Boolean).reduce((o, k) => (o || {})[k], object);

export default processFieldName;
