import React from 'react';

import If from 'bundles/components/common/If';
import { FormFieldInterface } from './types';

import './Input.scss';

const InputGroup = ({ register, rest }: FormFieldInterface) => {
  const { prependLabel, appendLabel, ..._rest } = rest;
  return (
    <div className="input-group">
      <If condition={prependLabel}>
        <div className="input-group-prepend">
          <span className="input-group-text">{prependLabel}</span>
        </div>
      </If>
      <input {..._rest} {...register} />
      <If condition={appendLabel}>
        <div className="input-group-append">
          <span className="input-group-text">{appendLabel}</span>
        </div>
      </If>
    </div>
  );
};

InputGroup.displayName = 'Input';

export default InputGroup;
