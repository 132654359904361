import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import If from 'bundles/components/common/If';
import { setPaginationList } from 'bundles/utils';
import { IPaginationProps } from './types';

const Pagination = ({ pagination = {}, handleClickPage }: IPaginationProps) => {
  const { t } = useTranslation();

  const handleClick = (page: number) => handleClickPage(page);

  return (
    <div className="flex justify-content-between pagination align-items-center">
      <div className="pagination-info position-static">
        {t('labels.pagination_info_message', { from: pagination.offset, to: pagination.to, total_count: pagination.total_entries })}
      </div>
      <If condition={pagination.total_pages > 1}>
        <div className="d-flex">
          <Button variant="link" onClick={() => handleClick(pagination.current_page - 1)} disabled={pagination.current_page === 1}>
            <i className="fal fa-chevron-left" />
          </Button>
          <div className="pagination-list">
            {setPaginationList(pagination.current_page, pagination.total_pages).map((e, i) =>
              <span key={i} onClick={() => handleClick(Number(e))} className={pagination.current_page === e ? 'current': undefined}>{e}</span>)}
          </div>

          <Button variant="link" className="next-page" onClick={() => handleClick(pagination.current_page + 1)} disabled={pagination.current_page === pagination.total_pages}>
            <i className="fal fa-chevron-right" />
          </Button>
        </div>

        <div>
          <span>{t('labels.page')}<strong>{t('labels.current_page_of_total', { current: pagination.current_page, total: pagination.total_pages })}</strong></span>
          <span>
            {t('labels.go_to_page')}:
            <input
              type="number"
              value={pagination.current_page}
              min="1"
              className="form-control w-auto d-inline-block ml-2 text-center"
              max={pagination.total_pages}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) : 1;
                handleClickPage(page);
              }} />
          </span>
        </div>
      </If>

    </div>
  );
};

export default Pagination;
