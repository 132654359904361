const browserUrl = {
  setFragments: function (fragments) {
    let newLocation = '';

    if (fragments) {
      if(window.location.search.includes('query') || window.location.hash.includes('query')) {
        newLocation = window.location.pathname + (window.location.search ? window.location.search : window.location.hash) + fragments ;
      } else {
        newLocation = window.location.pathname + fragments;
      }
    }

    window.history.replaceState(null, null, newLocation);
  }
};

export default browserUrl;
