import React from 'react';

import If from 'bundles/components/common/If';
import { TPlaceHolder } from './types';

import './PlaceHolder.scss';

const Card = ({ condition, className = '', size }: TPlaceHolder) =>
  <If condition={condition === undefined || condition}>
    <div className={`element-placeholder--row ${className}`.trim()}>
      <div className={'element-placeholder element-placeholder--card'} data-size={size} />
    </div>
  </If>;

export default Card;
