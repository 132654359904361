import { URL } from 'bundles/constants';
import axios from 'bundles/apis/defaults';
import { i18n, humanizeLabel } from 'bundles/utils';
import { FilterContextAction, FilterStateInterface } from 'bundles/contexts/FilterContext/types';

export const setMultiselectValuesFor = (selectedSubTypes: string[], availableSubTypes: Record<string, never>[]) => {
  if(selectedSubTypes) return availableSubTypes.filter((e: Record<string, never>) => selectedSubTypes.includes(e.id));
};

export const setAccTypeUrl = (accType: string) => accType?.toLowerCase() === 'consumer' ? URL.ADMINS_CONSUMERS : URL.ADMINS_MERCHANTS;

export const txvWalletParams = (url: string, params: string) => {
  const pathnameArray = location.pathname.split('/');
  const usp = new URLSearchParams(params);

  if (url === URL.ADMINS_TRANSACTIONS_HISTORY) {
    usp.append('txv_wallet_owner_id', pathnameArray.pop() as string);
    usp.append('txv_wallet_owner_type', pathnameArray.pop() as string);
  }

  return usp;
};

export const setCsvParams = (type?: string) => {
  const usp = new URLSearchParams(window.location.search);

  usp.append('locale', i18n.language);
  usp.append('reports_type', type || '');

  return usp;
};

export const getPageData = (state: FilterStateInterface, dispatch: (arg0: FilterContextAction) => void, additionalStateProps: Record<string, any> = {}) => {
  const { currentLoader, ...restAdditionalStateProps } = additionalStateProps;

  currentLoader && dispatch({ type: 'ON_TRIGGER_LOADER', payload: { currentLoader: currentLoader } });
  return axios
    .get(state.url, { params: new URLSearchParams(window.location.search) })
    .then(response => {
      const { [state.collection_name]: collection, [state.total_collection_name]: total, ...rest } = response.data;
      dispatch({ type: 'GET_PAGE_DATA', payload: { ...rest,  collection: collection, currentLoader: '', currentBadge: undefined, total: total, ...restAdditionalStateProps } });
    });
};

export const setBadges = (state: FilterStateInterface) => {
  const arrOfKeys = Object.keys(state.query_params || {});
  return arrOfKeys.map(k => state.query_params[k] && { humanizedName: humanizeLabel(k), name: k }).filter(Boolean);
};
