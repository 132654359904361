import React from 'react';

import If from 'bundles/components/common/If';

import './PlaceHolder.scss';

const Text = ({ condition, className = '', classNameParagraph = '', type, number = 1 }: { condition?: boolean; className?: string; classNameParagraph?: string; type: string; number?: number}) => {
  const textWidths = className.includes('w-') ? [''] : ['w-75', 'w-100', 'w-50'];
  const randomIntFromInterval = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

  return (
    <If condition={condition === undefined || condition}>
      <If condition={type === 'title'}>
        <div className={`element-placeholder--row ${className}`}>
          <h1 className="element-placeholder element-placeholder--title clear-both" />
        </div>
      </If>
      <If condition={type === 'paragraph'}>
        {[...Array(number)].map((p,i) =>
          <div key={i} className={`element-placeholder--row ${className} ${textWidths[randomIntFromInterval(0, textWidths.length - 1)]}`}>
            <p className={`element-placeholder element-placeholder--text clear-both ${classNameParagraph}`} />
          </div>
        )}
      </If>
    </If>
  );
};

export default Text;
