import React, { useState, SyntheticEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import Recaptcha from 'bundles/components/common/Recaptcha';
import { isValidEmail, isRequired } from 'bundles/components/common/Form/validations';
import { InputFormGroup, InputGroupFormGroup } from 'bundles/components/common/Form';
import { SignInFormValues, SignInFormProps } from './types';
import If from 'bundles/components/common/If';
import { URL } from 'bundles/constants';
import { useDocumentVisible } from 'bundles/hooks';

import './styles.scss';

const SignInForm = ({ email, recaptcha_key }: SignInFormProps) => {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({ disabled: false, passwordVisible: false, refreshReCaptcha: false });
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const documentVisible = useDocumentVisible();

  const isAdminsUrl = window.location.pathname === `/${i18n.language}${URL.ADMINS}` || window.location.pathname === URL.ADMINS;
  const searchQuery = isAdminsUrl ? '?user_type=admins' : window.location.search;

  const onSubmitForm = async (fd: SignInFormValues, e: SyntheticEvent) => {
    setState(prevState => ({ ...prevState, disabled: true }));
    (e.target as HTMLFormElement).submit();
  };

  useEffect(() => {
    const interval = setInterval(() => setState(prevState => ({ ...prevState, refreshReCaptcha: !prevState.refreshReCaptcha })), 120000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setState(prevState => ({ ...prevState, refreshReCaptcha: !prevState.refreshReCaptcha }));
  }, [documentVisible]);

  return (
    <Form onSubmit={handleSubmit(onSubmitForm)} action={`/${i18n.language}${URL.SESSION}${searchQuery}`.trim()} method="post" noValidate>
      <InputFormGroup
        type="email"
        name="email"
        label={t('labels.email_address')}
        register={register}
        defaultValue={email}
        validate={{ isRequired, isValidEmail }}
        errors={errors}
      />

      <InputGroupFormGroup
        type={state.passwordVisible ? 'text' : 'password'}
        label={t('labels.password')}
        name="password"
        register={register}
        validate={{ isRequired }}
        errors={errors}
        className="border-right-0"
        appendLabel={<FontAwesomeIcon
          icon={state.passwordVisible ? faEyeSlash : faEye}
          size="lg"
          role="button"
          className="text-gray mx-3"
          onClick={() => setState(prevState => ({ ...prevState, passwordVisible: !prevState.passwordVisible }))}
        />}
      />

      <Recaptcha sitekey={recaptcha_key} register={register} setValue={setValue} refreshReCaptcha={state.refreshReCaptcha} action="session" />

      <input value={t('labels.signin')} className="btn btn-primary btn-block" disabled={state.disabled} type="submit" />

      <If condition={ !isAdminsUrl }>
        <div className="text-center mt-4 d-block">
          <a className="forgot-password text-secondary" href={`/${i18n.language}${URL.RESET_PASSWORD_NEW}`}>{t('links.forgot_password')}</a>
        </div>
      </If>
    </Form>
  );
};

export default SignInForm;
