import React, { useRef, useEffect, useContext, FC } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { FilterContext } from 'bundles/contexts/FilterContext/FilterContextProvider';
import AdditionalCriteria from './AdditionalCriteria';
import { setUrlParams } from 'bundles/utils';
import { getPageData } from './utils';
import { ComponentInterface } from './types';
import { FilterContextInterface } from 'bundles/contexts/FilterContext/types';
import If from 'bundles/components/common/If';

const Filter = ({ FilterContent, AddCriteriaContent }: Pick<ComponentInterface, 'FilterContent' | 'AddCriteriaContent'>) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext<FilterContextInterface>(FilterContext);
  const { handleSubmit, setValue } = useFormContext();

  const filterEl = useRef<HTMLDivElement | null>(null);
  const addCriteriaBtnEl = useRef(null);

  const handleClickOutside = (e: Event): void => {
    if (filterEl.current && !filterEl.current.contains(e.target as Element)) {
      addCriteriaBtnEl.current !== e.target && dispatch({ type: 'TOGGLE_SHOW_ADD_CRITERIA', payload: { showAddCriteria: false } });

      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  const handleClickAddCriteria = () => {
    dispatch({ type: 'TOGGLE_SHOW_ADD_CRITERIA', payload: { showAddCriteria: !state.showAddCriteria } });
    document.addEventListener('click', handleClickOutside, true);
  };

  const onHandleSubmitFilter = (f: Record<string, any>) => {
    setUrlParams(f, true, ['All']);
    getPageData(state, dispatch, { showAddCriteria: false, tableRowAnimated: '' });
  };

  useEffect(() => {
    if(!state.query_params) return;
    for (const [key, value] of Object.entries(state.query_params)) {setValue(`query[${key}]`, value);}
  }, [state.query_params]);

  return (
    <div className="filter-wrapper">

      <Form onSubmit={handleSubmit(onHandleSubmitFilter)} className="w-100 js-no-disable-submit-form">
        <If condition={FilterContent}>
          { FilterContent && React.createElement(FilterContent as FC) }
        </If>
        <If condition={AddCriteriaContent}>
          <Button
            variant="link"
            className="filter__additional-criteria-link pr-0 pl-0"
            onClick={handleClickAddCriteria}
            ref={addCriteriaBtnEl}>
            <i className="fal fa-plus-circle" />
            {t('links.additional_criteria')}
          </Button>
          <div className="clear-both">
            <AdditionalCriteria ref={filterEl} AddCriteriaContent={AddCriteriaContent as FC} />
          </div>
        </If>
      </Form>
    </div>
  );
};

export default Filter;
